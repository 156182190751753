<template>
    <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="`${id}Label`" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-flex flex-column">
                    <h4 class="modal-title">{{ title }}</h4>
                    <p class="modal-subtitle">{{ subtitle }}</p>
                    </div>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal(id)"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bootstrap from 'bootstrap';

export default {
    name: 'Modal',

    props: {
        id: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    methods: {
        closeModal(id) { 
            const myModalEl = document.getElementById(id);
            const modal = bootstrap.Modal.getInstance(myModalEl);
            modal.toggle();
        }
    }
}
</script>
