<template>
    <div class="p-persona-profile">
        <div class="container-xl">
            <form id="save-persona-form" v-for="persona in personas($route.params.personaId)" :key="persona.id"> <!-- @submit.prevent="save" -->
                <PageHeader :title="$route.params.projectName" goBack :selectBtn="true ? $route.params.mode === 'edit' : false" selectId="selectProjectModal" :personaSaved="this.saved" @openModal="openModal">
                    <div v-if="$route.params.mode === 'edit'" class="d-flex">
                        <Button text="Preview" variant="primary" :to="`/${$route.params.projectName}/preview/${$route.params.personaType}/${persona.id}`" />
                        <div @click="save('draft', 'saveDraftBtn')">
                            <Button type="button" text="Save draft" variant="secondary" iconPosition="left" icon="icon-save" id="saveDraftBtn" />
                        </div>
                        <div @click="save('publish', 'savePersonaBtn')">
                            <Button type="button" text="Publish persona" variant="secondary" iconPosition="left" icon="icon-save" id="savePersonaBtn" /> <!-- type="submit" -->
                        </div>
                    </div>
                </PageHeader>
                <!-- Header (thumbnail + name + description) -->
                <div class="p-persona-profile__section p-persona-profile__section--header">
                    <div class="p-persona-profile__thumbnail">
                        <div v-if="$route.params.mode === 'edit'" class="d-flex flex-column align-items-center justify-content-center">
                            <input type="file" name="thumbnail" @change="previewImage($event, 'imageThumbnail')" accept="image/*">
                            <inline-svg :src="require(`../assets/svg/icon-plus.svg`)" />
                            <span>Add image</span>
                            <div class="image-preview" v-if="imageThumbnail.length > 0" :style="`background: url(${imageThumbnail}) no-repeat center center; background-size: cover;`"></div>
                            <!-- Icon on hover -->
                            <button v-if="imageThumbnail" class="edit-btn"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button>
                        </div>
                        <div v-else>
                            <div class="image-preview" :style="`background: url('${imageThumbnail}') no-repeat center center; background-size: cover;`"></div>
                        </div>
                    </div>
                    <div v-if="$route.params.mode === 'edit'">
                        <input class="p-persona-profile__name-input form-control form-control-lg" type="text" name="name" placeholder="Persona name" aria-label="Persona name" v-model="name" required>
                        <input class="p-persona-profile__description-input form-control" type="text" name="description" placeholder="'Persona role" aria-label="Persona description" v-model="description">
                    </div>
                    <div v-else>
                        <h1>{{ name }}</h1>
                        <p >{{ description }}</p>
                    </div>
                </div>
                <!-- Section: tags -->
                <div class="p-persona-profile__section p-persona-profile__section--keywords" :style="`${[ $route.params.mode === 'preview' && !this.hasTags ? `display: none;` : `display: block;` ]}`">
                    <h3>Keywords</h3>
                    <div v-if="$route.params.mode === 'edit'">
                        <div class="d-flex align-items-center flex-wrap">
                            <Badge v-for="(tag, index) in tags" :key="index" :index="index" type="tags" :text="tags[index].text == '' ? 'Tag' : tags[index].text" :placeholder="tag.placeholder" v-model="tags[index].text" :icon="tags[index].icon" variant="light" editable />
                        </div>
                        <Button text="Add a tag" icon="icon-plus" iconPosition="left" variant="link" modalId="addTagModal" />
                    </div>
                    <div v-else class="d-flex align-items-center flex-wrap">
                        <Badge v-for="(tag, index) in tags" :key="index" :index="index" type="tags" :text="tags[index].text" variant="light" :icon="tags[index].icon" />
                    </div>
                </div>
                <!-- Section: quote -->
                <div class="p-persona-profile__section p-persona-profile__section--quote" :style="`${[ $route.params.mode === 'preview' && this.quote == '' ? `display: none;` : `display: block;` ]}`">
                    <div v-if="$route.params.mode === 'edit'">
                        <div>
                            <textarea class="form-control" name="quote" placeholder="Type a quote or a tag line…" @focus="onFocus" @blur="onBlur" v-model="quote"></textarea>
                        </div>
                    </div>
                    <div v-else>
                        <h4>{{ quote }}</h4>
                    </div>
                </div>
                <!-- Section: keywords -->
                <div class="p-persona-profile__section p-persona-profile__section--keywords" :style="`${[ $route.params.mode === 'preview' && !this.hasKeywords ? `display: none;` : `display: block;` ]}`">
                    <h3>Keywords</h3>
                    <div v-if="$route.params.mode === 'edit'">
                        <div class="d-flex align-items-center flex-wrap">
                            <Badge v-for="(keyword, index) in keywords" :key="index" :index="index" type="keywords" :text="keyword == '' ? 'Keyword' : keyword[index]" v-model="keywords[index]" editable />
                        </div>
                        <Button text="Add a keyword" icon="icon-plus" iconPosition="left" variant="link" @click="addElement('keyword')" />
                    </div>
                    <div v-else class="d-flex align-items-center flex-wrap">
                        <Badge v-for="(keyword, index) in keywords" :key="index" :index="index" type="keywords" :text="keyword" />
                    </div>
                </div>
                <!-- Section: persona story -->
                <div class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" v-if="$route.params.personaType === 'role-based'" :style="`${[ $route.params.mode === 'preview' && this.story == '' ? `display: none;` : `display: flex;` ]}`">
                    <IconThumbnail icon="icon-book" :text="`${[ $route.params.personaId === 'new' ? `${name}'s story` : `${persona.name}'s story` ]}`" />
                    <div class="content">
                        <div v-if="$route.params.mode === 'edit'">
                            <textarea class="form-control" name="story" placeholder="• Type your text…" @focus="onFocus" @blur="onBlur" v-model="story"></textarea>
                        </div>
                        <div v-else>
                            <Markdown :source="this.story" />
                        </div>
                    </div>
                </div>
                <!-- Section: typical day -->
                <div class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" v-if="$route.params.personaType === 'goal-directed'" :style="`${[ $route.params.mode === 'preview' && persona.typicalDay == '' ? `display: none;` : `display: flex;` ]}`">
                    <IconThumbnail icon="icon-book" :text="`${[ $route.params.personaId === 'new' ? `${name}'s typical day` : `${persona.name}'s typical day` ]}`" />
                    <div class="content">
                        <div v-if="$route.params.mode === 'edit'">
                            <textarea class="form-control" name="typicalDay" placeholder="• Type your text…" @focus="onFocus" @blur="onBlur" v-model="typicalDay"></textarea>
                        </div>
                        <div v-else>
                            <Markdown v-if="persona.id !== 'new'" :source="persona.typicalDay" />
                            <Markdown v-else :source="typicalDay" />
                        </div>
                    </div>
                </div>
                <!-- Section: sliders -->
                <div class="p-persona-profile__section p-persona-profile__section--text flex-lg-row flex-column" :style="`${[ $route.params.mode === 'preview' && !this.hasSliders ? `display: none;` : `display: flex;` ]}`">
                    <IconThumbnail icon="icon-book" :text="`${[ $route.params.personaId === 'new' ? `How ${name} is like` : `What ${persona.name} is like` ]}`" />
                    <div class="content">
                        <div v-if="$route.params.mode === 'edit'">
                            <Slider v-for="(slider, index) in sliders" :key="index" :index="index" v-model:rangeValue="sliders[index].value" v-model:textLeftValue="sliders[index].textLeft" v-model:textRightValue="sliders[index].textRight" editable />
                            <Button text="Add a slider" icon="icon-plus" iconPosition="left" variant="link" @click="addElement('slider')" />
                        </div>
                        <div v-else>
                            <Slider v-for="(slider, index) in sliders" :key="index" :index="index" v-model:rangeValue="sliders[index].value" v-model:textLeftValue="sliders[index].textLeft" v-model:textRightValue="sliders[index].textRight" />
                        </div>
                    </div>
                </div>
                <!-- Section: Dynamic text components -->
                <div v-for="(textComponent, index) in textComponents" :key="index" class="p-persona-profile__section p-persona-profile__section--text p-persona-profile__section--dynamic-component flex-lg-row flex-column" :style="`${[ $route.params.mode === 'preview' && textComponent.content == '' ? `display: none;` : `display: flex;` ]}`">
                    <div v-if="$route.params.mode === 'edit'">
                        <IconThumbnail v-model:icon="textComponent.icon" v-model:text="textComponent.title" :id="textComponent.id" editable />
                    </div>
                    <div v-else>
                        <IconThumbnail :icon="textComponent.icon" :text="textComponent.title" />
                    </div>
                    <div class="content">
                        <div v-if="$route.params.mode === 'edit'">
                            <textarea class="form-control" :name="textComponent.title" placeholder="• Type your text…" @focus="onFocus" @blur="onBlur" v-model="textComponent.content"></textarea>
                        </div>
                        <div v-else>
                            <Markdown :source="textComponent.content" />
                        </div>
                    </div>
                </div>

                <!-- Section: add text component -->
                <div v-if="$route.params.mode === 'edit'" class="p-persona-profile__section p-persona-profile__section--add">
                    <Button text="Add text component" icon="icon-plus" iconPosition="left" variant="link" @click="addElement('textComponent')" />
                </div>

                <!-- Section: images -->
                <div class="p-persona-profile__section p-persona-profile__section--images">
                    <div class="d-flex">
                        <div>
                            <div class="image image--side" :style="`${[ $route.params.mode === 'preview' && this.imageTopLeft == '' ? `display: none;` : `display: block;` ]}`">
                                <div v-if="$route.params.mode === 'edit'">
                                    <input type="file" name="imageTopLeft" @change="previewImage($event, 'imageTopLeft')" accept="image/*">
                                    <span v-if="!this.imageTopLeft" class="d-flex flex-column align-items-center">
                                        <inline-svg :src="require(`../assets/svg/icon-plus.svg`)" />
                                        <span>Add image</span>
                                    </span>
                                    <div class="image-preview" v-if="imageTopLeft.length > 0" :style="`background: url('${imageTopLeft}') no-repeat center center; background-size: cover;`"></div>
                                    <span v-if="imageTopLeft" class="d-flex">
                                        <button class="edit-btn"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button>
                                        <button class="edit-btn" @click="deleteImage('imageTopLeft')"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></button>
                                    </span>
                                </div>
                                <div v-else>
                                    <div class="image-preview" :style="`background: url('${imageTopLeft}') no-repeat center center; background-size: cover;`"></div>
                                </div>
                            </div>
                            <div class="image image--side" :style="`${[ $route.params.mode === 'preview' && this.imageBottomLeft == '' ? `display: none;` : `display: block;` ]}`">
                                <div v-if="$route.params.mode === 'edit'">
                                    <input type="file" name="imageBottomLeft" @change="previewImage($event, 'imageBottomLeft')" accept="image/*">
                                    <span v-if="!this.imageBottomLeft" class="d-flex flex-column align-items-center">
                                        <inline-svg :src="require(`../assets/svg/icon-plus.svg`)" />
                                        <span>Add image</span>
                                    </span>
                                    <div class="image-preview" v-if="imageBottomLeft.length > 0" :style="`background: url('${imageBottomLeft}') no-repeat center center; background-size: cover;`"></div>
                                    <span v-if="imageBottomLeft" class="d-flex">
                                        <button class="edit-btn"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button>
                                        <button class="edit-btn" @click="deleteImage('imageBottomLeft')"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></button>
                                    </span>
                                </div>
                                <div v-else>
                                    <div class="image-preview" :style="`background: url('${imageBottomLeft}') no-repeat center center; background-size: cover;`"></div>
                                </div>
                            </div>
                        </div>
                        <div class="image image--main" :style="`${[ $route.params.mode === 'preview' && this.imageMain == '' ? `display: none;` : `display: block;` ]}`">
                            <div v-if="$route.params.mode === 'edit'">
                                <input type="file" name="imageMain" @change="previewImage($event, 'imageMain')" accept="image/*">
                                <span v-if="!this.imageMain" class="d-flex flex-column align-items-center">
                                    <inline-svg :src="require(`../assets/svg/icon-plus.svg`)" />
                                    <span>Add image</span>
                                </span>
                                <div class="image-preview" v-if="imageMain.length > 0" :style="`background: url('${imageMain}') no-repeat center center; background-size: cover;`"></div>
                                <span v-if="imageMain" class="d-flex">
                                    <button class="edit-btn"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button>
                                    <button class="edit-btn" @click="deleteImage('imageMain')"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></button>
                                </span>
                            </div>
                            <div v-else>
                                <div class="image-preview" :style="`background: url('${imageMain}') no-repeat center center; background-size: cover;`"></div>
                            </div>
                        </div>
                    </div>
                    <div class="image image--bottom" :style="`${[ $route.params.mode === 'preview' && this.imageBottom == '' ? `display: none;` : `display: block;` ]}`">
                        <div v-if="$route.params.mode === 'edit'">
                            <input type="file" name="imageBottom" @change="previewImage($event, 'imageBottom')" accept="image/*">
                            <span v-if="!this.imageBottom" class="d-flex flex-column align-items-center">
                                <inline-svg :src="require(`../assets/svg/icon-plus.svg`)" />
                                <span>Add image</span>
                            </span>
                            <div class="image-preview" v-if="imageBottom.length > 0" :style="`background: url('${imageBottom}') no-repeat center center; background-size: cover;`"></div>
                            <span v-if="imageBottom" class="d-flex">
                                <button class="edit-btn"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button>
                                <button class="edit-btn" @click="deleteImage('imageBottom')"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></button>
                            </span>
                        </div>
                        <div v-else>
                            <div class="image-preview" :style="`background: url('${imageBottom}') no-repeat center center; background-size: cover;`"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- Modal: add tag -->
        <Modal id="addTagModal" title="Select an icon" subtitle="">
            <form id="add-tag-form" @submit.prevent="addElement('tag')">
                <div class="mb-32 mt-32">
                    <ul class="list-icons d-flex flex-wrap">
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-activity' ? 'active' : '' ]}`" data-icon="icon-activity">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-activity.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-book' ? 'active' : '' ]}`" data-icon="icon-book">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-book.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-bookmark' ? 'active' : '' ]}`" data-icon="icon-bookmark">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-bookmark.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-briefcase' ? 'active' : '' ]}`" data-icon="icon-briefcase">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-briefcase.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-card' ? 'active' : '' ]}`" data-icon="icon-card">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-card.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-cart' ? 'active' : '' ]}`" data-icon="icon-cart">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-cart.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-check' ? 'active' : '' ]}`" data-icon="icon-check">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-check.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-clipboard' ? 'active' : '' ]}`" data-icon="icon-clipboard">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-clipboard.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-clock' ? 'active' : '' ]}`" data-icon="icon-clock">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-clock.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-coffee' ? 'active' : '' ]}`" data-icon="icon-coffee">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-coffee.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-credit-card' ? 'active' : '' ]}`" data-icon="icon-credit-card">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-credit-card.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-edit' ? 'active' : '' ]}`" data-icon="icon-edit">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-flag' ? 'active' : '' ]}`" data-icon="icon-flag">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-flag.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-frown' ? 'active' : '' ]}`" data-icon="icon-frown">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-frown.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-heart' ? 'active' : '' ]}`" data-icon="icon-heart">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-heart.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-info' ? 'active' : '' ]}`" data-icon="icon-info">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-info.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-meh' ? 'active' : '' ]}`" data-icon="icon-meh">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-meh.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-phone' ? 'active' : '' ]}`" data-icon="icon-phone">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-phone.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-pin' ? 'active' : '' ]}`" data-icon="icon-pin">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-pin.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-plus' ? 'active' : '' ]}`" data-icon="icon-plus">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-plus.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-save' ? 'active' : '' ]}`" data-icon="icon-save">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-save.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-share' ? 'active' : '' ]}`" data-icon="icon-share">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-share.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-star' ? 'active' : '' ]}`" data-icon="icon-star">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-star.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-thumbs-up' ? 'active' : '' ]}`" data-icon="icon-thumbs-up">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-thumbs-up.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-thumbs-down' ? 'active' : '' ]}`" data-icon="icon-thumbs-down">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-thumbs-down.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-tool' ? 'active' : '' ]}`" data-icon="icon-tool">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-tool.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-trash' ? 'active' : '' ]}`" data-icon="icon-trash">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-truck' ? 'active' : '' ]}`" data-icon="icon-truck">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-truck.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-tv' ? 'active' : '' ]}`" data-icon="icon-tv">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-tv.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-user' ? 'active' : '' ]}`" data-icon="icon-user">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-user.svg`)" /></a>
                        </li>
                        <li @click="updateTagIcon" :class="`${[ tagIcon == 'icon-zap' ? 'active' : '' ]}`" data-icon="icon-zap">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-zap.svg`)" /></a>
                        </li>
                    </ul>
                </div>
                <button type="submit" class="btn btn-secondary">Save</button>
            </form>
        </Modal>
        <!-- Modal: add text component (todo: merge with add tag modal) -->
        <Modal id="changeIconModal" title="Select an icon" subtitle="">
            <form id="change-icon-form" @submit.prevent="updateComponentIcon"> 
                <div class="mb-32 mt-32">
                    <ul class="list-icons d-flex flex-wrap">
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-activity' ? 'active' : '' ]}`" data-icon="icon-activity">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-activity.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-book' ? 'active' : '' ]}`" data-icon="icon-book">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-book.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-bookmark' ? 'active' : '' ]}`" data-icon="icon-bookmark">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-bookmark.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-briefcase' ? 'active' : '' ]}`" data-icon="icon-briefcase">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-briefcase.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-card' ? 'active' : '' ]}`" data-icon="icon-card">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-card.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-cart' ? 'active' : '' ]}`" data-icon="icon-cart">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-cart.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-check' ? 'active' : '' ]}`" data-icon="icon-check">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-check.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-clipboard' ? 'active' : '' ]}`" data-icon="icon-clipboard">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-clipboard.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-clock' ? 'active' : '' ]}`" data-icon="icon-clock">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-clock.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-coffee' ? 'active' : '' ]}`" data-icon="icon-coffee">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-coffee.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-credit-card' ? 'active' : '' ]}`" data-icon="icon-credit-card">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-credit-card.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-edit' ? 'active' : '' ]}`" data-icon="icon-edit">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-flag' ? 'active' : '' ]}`" data-icon="icon-flag">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-flag.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-frown' ? 'active' : '' ]}`" data-icon="icon-frown">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-frown.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-heart' ? 'active' : '' ]}`" data-icon="icon-heart">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-heart.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-info' ? 'active' : '' ]}`" data-icon="icon-info">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-info.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-meh' ? 'active' : '' ]}`" data-icon="icon-meh">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-meh.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-phone' ? 'active' : '' ]}`" data-icon="icon-phone">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-phone.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-pin' ? 'active' : '' ]}`" data-icon="icon-pin">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-pin.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-plus' ? 'active' : '' ]}`" data-icon="icon-plus">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-plus.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-save' ? 'active' : '' ]}`" data-icon="icon-save">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-save.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-share' ? 'active' : '' ]}`" data-icon="icon-share">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-share.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-star' ? 'active' : '' ]}`" data-icon="icon-star">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-star.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-thumbs-up' ? 'active' : '' ]}`" data-icon="icon-thumbs-up">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-thumbs-up.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-thumbs-down' ? 'active' : '' ]}`" data-icon="icon-thumbs-down">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-thumbs-down.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-tool' ? 'active' : '' ]}`" data-icon="icon-tool">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-tool.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-trash' ? 'active' : '' ]}`" data-icon="icon-trash">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-trash.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-truck' ? 'active' : '' ]}`" data-icon="icon-truck">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-truck.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-tv' ? 'active' : '' ]}`" data-icon="icon-tv">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-tv.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-user' ? 'active' : '' ]}`" data-icon="icon-user">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-user.svg`)" /></a>
                        </li>
                        <li @click="updateTextComponentIcon" :class="`${[ textComponentIcon == 'icon-zap' ? 'active' : '' ]}`" data-icon="icon-zap">
                            <a href="javascript:void(0)"><inline-svg :src="require(`../assets/svg/icon-zap.svg`)" /></a>
                        </li>
                    </ul>
                </div>
                <button type="submit" class="btn btn-secondary">Save</button>
            </form>
        </Modal>
        <!-- Modal: select project -->
        <Modal id="selectProjectModal" title="Select another project" subtitle="Your persona could now be seend by people who have access to the project, if it has been shared.">
            <ul class="p-persona-profile__projects-list mb-32 mt-32">
                <li v-on:click="selectAnotherProject($event, project.name)" v-for="(project) in projects" :key="project.name" :class="`p-persona-profile__select-project-btn ${[ project.name === this.newSelectedProject ? 'active' : '' ]}`"><a href="#">{{ project.name }}</a> <inline-svg v-if="project.name === this.newSelectedProject" :src="require(`../assets/svg/icon-check.svg`)" /></li>
            </ul>
            <div class="d-flex">
                <button @click="saveToAnotherProject(true)" class="btn btn-secondary">Save change</button>
                <button @click="saveToAnotherProject(false)" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            </div>
        </Modal>
        <!-- Modal: changes will be lost -->
        <Modal id="changesModal" title="Wait! Changes will be lost…" subtitle="Do you want to save changes you made to that persona or leave it unsaved?">
            <div class="d-flex mt-40">
                <button @click="save('draft')" class="btn btn-secondary">Save draft</button>
                <button @click="goBack" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close">Discard changes</button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { firebase } from '../firebase';
    import { personasCollection } from '../firebase';
    import { mapActions, mapGetters,  } from 'vuex';
    import * as bootstrap from 'bootstrap';
    import InlineSvg from 'vue-inline-svg';
    import Markdown from 'vue3-markdown-it';
    import Button from '../components/Button.vue';
    import IconThumbnail from '../components/IconThumbnail.vue';
    import Modal from '../components/Modal.vue';
    import PageHeader from '../components/PageHeader.vue';
    import Badge from '../components/Badge.vue';
    import Slider from '../components/Slider.vue';

    export default {
        name: 'PersonaProfile',

        components: {
            InlineSvg,
            Markdown,
            Button,
            IconThumbnail,
            Modal,
            PageHeader,
            Badge,
            Slider,
        },

        computed: {
            ...mapGetters({
                fetchPersona: 'personas/fetchPersona',
                projectsFiles: 'projects/projects',
            }),
            personas() {
                return this.fetchPersona
            },
            projects() {
                return this.projectsFiles
            },
        },

        data() {
            return {
                // Images
                imageThumbnail: '',
                imageTopLeft: '',
                imageBottomLeft: '',
                imageMain: '',
                imageBottom: '',

                // Input / textarea fields
                name: '',
                description: '',
                tags: [
                    { icon: 'icon-heart', text: '', placeholder: 'Marital status' },
                    { icon: 'icon-briefcase', text: '', placeholder: 'Job' },
                    { icon: 'icon-book', text: '', placeholder: 'Education' },
                    { icon: 'icon-pin', text: '', placeholder: 'Location' },
                ],
                quote: '',
                keywords: ['', '', '', ''],
                story: '',
                typicalDay: '',
                sliders: [
                    { textLeft: '', textRight: '', value: '50' },
                    { textLeft: '', textRight: '', value: '50' },
                ],
                goals: '',
                obstacles: '',
                questions: '',
                tools: '',
                responsabilities: '',
                textComponents: [
                    { id: 0, icon: "icon-clipboard", title: "Goal and motivation", content: "" },
                    { id: 1, icon: "icon-flag", title: "Obstacles Persona faces", content: "" },
                    { id: 2, icon: "icon-info", title: "Tools Persona uses", content: "" },
                    { id: 3, icon: "icon-tool", title: "Goal and motivation", content: "" },
                    { id: 4, icon: "icon-briefcase", title: "Job responsabilities", content: "" },
                ],

                // State
                isPublished: false,

                // Temporary data
                projectId: '',
                newSelectedProject: this.$route.params.projectName,

                tagIcon: 'icon-activity',
                textComponentIcon: 'icon-book',
                textComponentIndex: '',

                hasKeywords: false,
                hasSliders: false,
                hasTags: false,

                saved: false,
            }
        },

        methods: {
            ...mapActions('personas', {
                savePersona: 'savePersona',
                updatePersona: 'updatePersona',
            }),

            // Possible types: tag, keyword, slider, textComponent
            addElement(type) {
                switch(type) {
                    case 'tag':
                        this.tags.push({ 'icon': this.tagIcon, 'text': '', 'placeholder': 'New tag' });
                        this.closeModal('addTagModal');
                        break;
                    case 'keyword':
                        this.keywords.push('');
                        break;
                    case 'slider':
                        this.sliders.push({ 'textLeft': '', 'textRight': '', 'value': 50 });
                        break;
                    case 'textComponent':
                        this.textComponents.push({ 'id': 0, 'icon': this.textComponentIcon, 'title': '', 'content': '' });

                        for(let i = 0; i < this.textComponents.length; i++) {
                            this.textComponents[i].id = i;
                        }
                        break;
                    default:
                        return false;
                }
            },

            updateComponentIcon() {
                for (let i in this.textComponents) {
                    if (this.textComponents[i].id == this.textComponentIndex) {
                        this.textComponents[i].icon = this.textComponentIcon;
                        break;
                    }
                }
                this.closeModal('changeIconModal');
            },
            updateComponentIndex(id) {
                this.textComponentIndex = id;
            },

            removeElement(index, type) {
                if(type === 'tags') {
                    this.tags.splice(index, 1);
                } else if(type === 'keywords') {
                    this.keywords.splice(index, 1);
                } else if(type === 'slider') {
                    this.sliders.splice(index, 1);
                } else {
                    console.log('element type error ' + type);
                }
            },
            deleteImage(imagePosition) {
                switch(imagePosition) {
                    case 'imageTopLeft':
                        this.imageTopLeft = '';
                        break;
                    case 'imageBottomLeft':
                        this.imageBottomLeft = '';
                        break;
                    case 'imageMain':
                        this.imageMain = '';
                        break;
                    case 'imageBottom':
                        this.imageBottom = '';
                        break;
                    default:
                        return false;
                }
            },

            openModal(id) {
                const myModalEl = document.getElementById(id);
                const modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
                modal.toggle();
            },
            closeModal(id) {
                const myModalEl = document.getElementById(id);
                const modal = bootstrap.Modal.getInstance(myModalEl);
                modal.toggle();
            },

            goBack() {
                this.$router.push({ name: 'Project', params: { name: this.$route.params.projectName } })
            },

            onBlur(e) {
                e.target.parentElement.parentElement.parentElement.classList.remove('focus');
            },
            onFocus(e) {
                e.target.parentElement.parentElement.parentElement.classList.add('focus');
            },

            previewImage(event,position) {
              this.uploadValue = 0;
              const img = event.target.files[0];
              this.upload(img,position)
            },

            upload(img,position) {
              const imgData = img;
               const storageRef = firebase.storage().ref(`${imgData.name}`).put(imgData);
                storageRef.on(`state_changed`, snapshot => {
                    this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }, error => {console.log(error.message)},
                () => {
                    this.uploadValue = 100;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                      if(position === 'imageThumbnail') {
                        this.imageThumbnail = url;
                      }
                      switch(position) {
                        case 'imageThumbnail':
                          this.imageThumbnail = url;
                          break;
                        case 'imageTopLeft':
                          this.imageTopLeft = url;
                          break;
                        case 'imageBottomLeft':
                            this.imageBottomLeft = url;
                            break;
                        case 'imageMain':
                            this.imageMain = url;
                            break;
                        case 'imageBottom':
                            this.imageBottom = url;
                            break;
                        default:
                          return false;
                      }

                    })
                })
            },

            save(personaState, btnId) {
                this.projects.forEach(project => {
                    if (project.name == this.$route.params.projectName) {
                        this.projectId = project.id;
                    }
                })

                if (this.isPublished == false && personaState == 'publish') {
                    this.isPublished = true;
                }

                let payload = { 'parentProject': this.$route.params.projectName, 'parentProjectId': this.projectId, 'personaType': this.$route.params.personaType, 'tags': this.tags, 'keywords': this.keywords, 'story': this.story, 'typicalDay': this.typicalDay, 'sliders': this.sliders, 'textComponents': this.textComponents, 'thumbnail': this.imageThumbnail, 'imageTopLeft': this.imageTopLeft, 'imageBottomLeft': this.imageBottomLeft, 'imageMain': this.imageMain, 'imageBottom': this.imageBottom, 'isPublished': this.isPublished, 'btnId': btnId }

                if (this.$route.params.personaId === 'new') {
                    this.savePersona(payload);
                } else {
                    payload.id = this.$route.params.personaId;
                    this.updatePersona(payload);
                }
                this.saved = true; // Not ideal, should be in store after successful save/update
                if(document.getElementById('changesModal').ariaModal) { // same as above
                    this.closeModal('changesModal');
                    this.$router.push({ name: 'Project', params: { name: this.$route.params.projectName } })
                }
            },

            updateName(e) {
                e.target.setAttribute('name', e.target.value);
            },
            updateTagIcon(e) {
                this.tagIcon = e.currentTarget.getAttribute('data-icon');
            },
            updateTextComponentIcon(e) {
                this.textComponentIcon = e.currentTarget.getAttribute('data-icon');
            },

            selectAnotherProject(e, projectName) {
                this.newSelectedProject = projectName;

                // Code below doesnt work 100% when using add/remove class
                const elements = document.querySelectorAll('.p-persona-profile__select-project-btn');
                for(let i = 0; i < elements.length; i++) {
                    // if(elements[i].classList.contains('borderBottom')) {
                    //     elements[i].classList.remove('borderBottom');
                    // } else if (elements[i].classList.contains('borderTop')) {
                    //     elements[i].classList.remove('borderTop');
                    // }
                    elements[i].style.borderBottomColor = '#E9EAED';
                    elements[i].style.borderTopColor = '#E9EAED';
                }

                e.target.parentElement.style.borderBottomColor = '#00A896';
                e.target.parentElement.style.borderTopColor = '#00A896';
                if(e.target.parentElement.previousElementSibling !== null) {
                    // e.target.parentElement.previousElementSibling.classList.add('borderBottom');
                    e.target.parentElement.previousElementSibling.style.borderBottomColor = '#00A896';
                }
                if(e.target.parentElement.nextElementSibling !== null) {
                    // e.target.parentElement.nextElementSibling.classList.add('borderTop');
                    e.target.parentElement.nextElementSibling.style.borderTopColor = '#00A896';
                }
                

            },
            saveToAnotherProject(save) {
                if(save === true) {
                    this.$router.push({ name: 'PersonaProfile', params: { projectName: this.newSelectedProject } })

                    // Close select another project modal
                    const modal = document.getElementById(`selectProjectModal`);
                    // Change state like in hidden modal
                    modal.classList.remove('show');
                    modal.setAttribute('aria-hidden', 'true');
                    modal.setAttribute('style', 'display: none');
                    // Get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                    // Remove opened modal backdrop
                    document.body.removeChild(modalBackdrops[0]);
                    document.body.style.overflow = 'scroll';
                } else {
                    this.newSelectedProject = this.$route.params.projectName;
                }
            },
        },

        // Populate fields
        created: function() {
            if(this.$route.params.personaId !== 'new') {
                personasCollection.doc(this.$route.params.personaId).get().then((persona) => {
                    this.name = persona.data().name;
                    this.description = persona.data().description;
                    this.quote = persona.data().quote;
                    this.tags = persona.data().tags;
                    this.keywords = persona.data().keywords;
                    this.story = persona.data().story;
                    this.typicalDay = persona.data().typicalDay;
                    this.sliders = persona.data().sliders;
                    this.textComponents = persona.data().textComponents;
                    this.imageThumbnail = persona.data().thumbnail;
                    this.imageTopLeft = persona.data().imageTopLeft;
                    this.imageBottomLeft = persona.data().imageBottomLeft;
                    this.imageMain = persona.data().imageMain;
                    this.imageBottom = persona.data().imageBottom;
                    this.isPublished = persona.data().isPublished;
                })
                .then(() => {
                    // Keywords
                    for(let i = 0; i < this.keywords.length; i++) {
                        if(this.keywords[i] !== '') {
                            this.hasKeywords = true;
                        }
                    }
                    // Sliders
                    for(let i = 0; i < this.sliders.length; i++) {
                        if(this.sliders[i].textLeft !== '') {
                            this.hasSliders = true;
                        }
                    }
                    // Tags
                    for(let i = 0; i < this.tags.length; i++) {
                        if(this.tags[i].text !== '') {
                            this.hasTags = true;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        },

        watch: {
            "$route.params.mode": {
                handler: function(value) {
                    if(value == 'preview') {
                        // Keywords
                        for(let i = 0; i < this.keywords.length; i++) {
                            if(this.keywords[i] !== '') {
                                this.hasKeywords = true;
                            }
                        }
                        // Sliders
                        for(let i = 0; i < this.sliders.length; i++) {
                            if(this.sliders[i].textLeft !== '') {
                                this.hasSliders = true;
                            }
                        }
                        // Tags
                        for(let i = 0; i < this.tags.length; i++) {
                            if(this.tags[i].text !== '') {
                                this.hasTags = true;
                            }
                        }
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    }
</script>
